import axios from "axios";
import VueCookies from 'vue-cookies'

// import { Notification, MessageBox, Message, Loading } from 'element-ui'
// import store from '@/store'
// import { getToken } from '@/utils/auth'
// import errorCode from '@/utils/errorCode'
// import { tansParams, blobValidate } from "@/utils/ruoyi";
// import cache from '@/plugins/cache'
// import { saveAs } from 'file-saver'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// export function request(config, success, failure) {
	const request = axios.create({
		// baseURL: 'https://ncca.com.cn/prod-api/',
		// baseURL: 'http://192.168.216.177:81/dev-api', // 通过/api别名指定后端路由
		// baseURL: '/dev-api', // 通过/api别名指定后端路由
		baseURL: '/prod-api', // 通过/api别名指定后端路由
		
		timeout: 10000,
		// headers:{
		// 	Authorization : `Bearer ${VueCookies.get('access_token')}`
		// },
	})

	// 添加请求拦截器
	request.interceptors.request.use(function(config) {
		// 在发送请求之前做些什么
		// var isChinese = localStorage.getItem("isChinese");

		// 是否需要设置 token
		// const isToken = (config.headers || {}).isToken === false
		
		// if (localStorage.getItem('access_token') && !isToken) {
		// 	config.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;// 让每个请求携带自定义token 请根据实际情况自行修改
		// }
		// config.headers['Authorization'] = 'Bearer' + this.$cookie.get('access_token');// 让每个请求携带自定义token 请根据实际情况自行修改
		
		config.headers['Authorization'] = `Bearer ${VueCookies.get('access_token')}`;// 让每个请求携带自定义token 请根据实际情况自行修改
		// console.log(config)
		// console.log(VueCookies.get('access_token'))
		return config;
	}, function(error) {
		// 对请求错误做些什么
		// return Promise.reject(error);  
	});

	// // 添加响应拦截器
	// request.interceptors.response.use(function(response) {
	// 	// 对响应数据做点什么
	// 	return response.data;
	// 	}, function(error) {
	// 	// 对响应错误做点什么
	// 	return Promise.reject(error);
	// });

	// request(config)
	// 	.then(res => {
	// 		success(res);
	// 	})
	// 	.catch(err => {
	// 		failure(err);
	// 	})
// }

export default request;