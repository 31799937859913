import { createApp } from 'vue'
// import Vue  from 'vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueCookies from 'vue-cookies'
import { createStore } from 'vuex'
import App from './App.vue'

const store = createStore({
  state () {
    return {
        link_item_index: 0,
        addressId: 0,
    }
  },
  mutations: {
    setLinkItemIndex (state, index) {
      state.link_item_index = index
    },
    setAddressId (state, index) {
      state.addressId = index
    },
  }
})

import './static/font/font.css'

router.afterEach((to, from, next) => {
    window.scrollTo(0, 0)
})

const app = createApp(App)
app.use(ElementPlus)
app.use(router)
app.use(VueCookies)
app.use(store)
app.mount('#app')

