import request from '@/utils/request'

// 首页数据  http://192.168.216.199:81/dev-api/identify/goods/noToken/getFirstPage
export function getGoodsNum() {
    return request({
        method: 'get',
		url: '/identify/goods/noToken/getFirstPage',
        params: {}
    })
}

// 公告列表 http://192.168.216.199:81/dev-api/system/notice/noToken/listNoTice/2
export function listNoToken() {
    return request({
        method: 'get',
        url: '/system/notice/noToken/listNoTice/2',
        params: {}
    })
}

// 公告列表 http://192.168.216.199:81/dev-api/system/notice/noToken/listNoTice/2
export function listNoToken5() {
    return request({
        method: 'get',
        url: '/system/notice/noToken/listNoTice/5',
        params: {}
    })
}
// 公告详情 http://192.168.216.199:81/dev-api/system/notice/noToken/getNoticeInfo/1
export function getInfoNoToken(noticeId) {
    return request({
        // method: 'get',
        url: '/system/notice/noToken/getNoticeInfo/'+noticeId,
        params: {}
    })
}
// 公司信息 http://192.168.216.199:81/dev-api/system/notice/noToken/getNoticeInfoByCode/4
export function listCompanyInfo() {
    return request({
        // method: 'get',
        url: '/system/notice/noToken/getNoticeInfoByCode/4',
        params: {}
    })
}
// 关于我们 http://192.168.216.83:81/dev-api/system/notice/noToken/getAboutUs
export function getAboutUs() {
    return request({
        // method: 'get',
        url: '/system/notice/noToken/getAboutUs',
        params: {}
    })
}
// 首页图片 http://192.168.216.148:81/dev-api/system/notice/noToken/getPageImage
export function getPageImage() {
    return request({
        method: 'get',
        url: '/system/notice/noToken/getPageImage',
        params: {}
    })
}

// 藏品详情  http://192.168.216.199:81/dev-api/identify/goods/noToken/getGoodsInfoByCode?code=ca00100000002
export function getGoodsInfoByCode(code) {
    return request({
        method: 'get',
        url: '/identify/goods/noToken/getGoodsInfoByCode',
        params: {
            'code': code
        }
    })
}

// 登录验证 ： http://192.168.216.199:81/dev-api/auth/pc/login
export function checkLogin(token) {
    return request({
        method: 'post',
        url: '/auth/pc/checkLogin',
        data: {
            'access_token': token
        }
    })
}

// 获取用户信息 http://192.168.216.142:81/dev-api/mobile/customer/getCustomerInfo
export function getCustomerInfo() {
    return request({
        method: 'get',
        url: '/mobile/customer/getCustomerInfo',
        params: {}
    })
}

// 登录 ： http://192.168.216.199:81/dev-api/auth/pc/login
//参数 ：{
//     "customerPhone": "18642085799",
//     "passwd": "123456"
// }
// //返回结果：{
//     "code": 200,
//     "msg": null,
//     "data": {
//         "access_token": "eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2lkIjoxMCwidXNlcl9rZXkiOiI0ZGY5OTFlNS1hODNlLTQ0NGEtYjY1Yi05Y2YxODI2NzU2YzYiLCJ1c2VybmFtZSI6Indhbmd3YW5nIn0.mBBBLSB44MwnvxA5uYfVCWFoRIjFqfi6ZBcuNU_OoL9PR6EgAXmER51-Jf6HgisereqBaTIXVUgj9EgyI-ilhw",
//         "expires_in": 720
//     }
// }
export function login(data) {
	// data = typeof data === 'object' ? JSON.stringify(data) : data
    return request({
        'method': 'post',
        'url': '/auth/pc/login',
        'data': data,
    })
}
//注册：http://127.0.0.1:81/dev-api/auth/pc/register
//参数：{
// 	"customerName":"王磊",
// 	"customerPhone":"18645614561",
// 	"passwd":"123456",
// 	"confirmPassword":"123456",
// 	"checkCode":"123456"//短信验证码
// }
// 返回结果：{
//     "code": 200,
//     "msg": "注册成功",
//     "data": {
//     }
// }
export function register(data) {
	data = typeof data === 'object' ? JSON.stringify(data) : data
    return request({
        method: 'post',
        url: '/auth/pc/register',
        data: data,
    })
}
// 忘记密码 editPwd
export function editPwd(data){
	data = typeof data === 'object' ? JSON.stringify(data) : data
    return request({
        method: 'post',
        url: '/auth/pc/editPwd',
        data: data,
    })
}
// 送评协议 http://192.168.216.83:81/dev-api/system/notice/noToken/getAgreement
export function getAgreement() {
    return request({
        method: 'get',
        url: '/system/notice/noToken/getAgreement',
        params: {}
    })
}
// 送评公司列表 http://192.168.216.83:81/dev-api/mobile/pchome/getCompanyList
export function getCompanyList() {
    return request({
        // method: 'get',
        url: '/mobile/pchome/getCompanyList',
        params: {}
    })
}
// 获取默认用户地址 http://192.168.216.83:81/dev-api/mobile/pchome/getCustomerAddress
export function getCustomerAddress(params) {
    return request({
        // method: 'get',
        url: '/mobile/pchome/getCustomerAddress',
        params: params,
    })
}
// 用户地址列表 http://192.168.216.83:81/dev-api/mobile/pchome/customerAddressList
export function customerAddressList() {
    return request({
        // method: 'get',
        url: '/mobile/pchome/customerAddressList',
        // params: {}
    })
}
// 添加收货地址 http://192.168.216.83:81/dev-api/mobile/pchome/insertCustomerAddress
export function insertCustomerAddress(data) {
    return request({
        method: 'post',
        url: '/mobile/pchome/insertCustomerAddress',
        data: data,
    })
}
// 修改收货地址 http://192.168.216.83:81/dev-api/mobile/pchome/editCustomerAddress
export function editCustomerAddress(data) {
    return request({
        method: 'post',
        url: '/mobile/pchome/editCustomerAddress',
        data: data,
    })
}
// 删除收货地址 http://192.168.216.83:81/dev-api/mobile/pchome/delCustomerAddress
export function delCustomerAddress(params) {
    return request({
        method: 'get',
        url: '/mobile/pchome/delCustomerAddress',
        params: params,
    })
}
// 新增订单 http://192.168.216.142:81/dev-api/mobile/pchome/saveOrder
export function saveOrder(data) {
    return request({
        method: 'post',
        url: '/mobile/pchome/saveOrder',
        data: data,
    })
}
// 获取订单列表 http://192.168.216.142:81/dev-api/mobile/pchome/myOrder  orderStatus (0:全部1:待付款 2:待确认 3:待发货 4:已发货)
export function myOrder(params) {
    return request({
        method: 'get',
        url: '/mobile/pchome/myOrder',
        params: params,
    })
}
// 获取订单 http://192.168.216.142:81/dev-api/mobile/pchome/getOrder
export function getOrder(params) {
    return request({
        method: 'get',
        url: '/mobile/pchome/getOrder',
        params: params,
    })
}
// 积分列表 http://192.168.216.142:81/dev-api/mobile/pchome/myScore
export function myScore(params) {
    return request({
        method: 'get',
        url: '/mobile/pchome/myScore',
        params: params,
    })
}
// 快递公司列表 http://192.168.216.142:81/dev-api/system/dict/data/noToken/listKuaidiCompany
export function listKuaidiCompany() {
    return request({
        method: 'get',
        url: '/system/dict/data/noToken/listKuaidiCompany',
        params: {},
    })
}
// 送评 http://192.168.216.142:81/dev-api/mobile/pchome/insertKuaidi
export function insertKuaidi(data) {
    return request({
        method: 'post',
        url: '/mobile/pchome/insertKuaidi',
        data: data,
    })
}
// 新增订单 http://192.168.216.142:81/dev-api/mobile/order/pay/ + orderCode
export function weChatNativePay(data, orderCode) {
    return request({
        method: 'post',
        url: '/mobile/order/pay/' + orderCode,
        data: data,
    })
}
// 修改手机号 http://192.168.216.142:81/dev-api/mobile/customer/editTel
export function editTel(data) {
    return request({
        method: 'post',
        url: '/mobile/customer/editTel',
        data: data,
    })
}
// 修改客户名称 http://192.168.216.142:81/dev-api/mobile/customer/editCustomerName
export function editCustomerName(params) {
    return request({
        method: 'get',
        url: '/mobile/customer/editCustomerName',
        params: params,
    })
}
// 取消订单 http://192.168.216.83:81/dev-api/mobile/order/refund-score/{orderCode}
export function cancelOrder(orderCode) {
    return request({
        method: 'post',
        url: '/mobile/order/refund-score/' + orderCode,
        data: {},
    })
}
// 获取物流信息 http://192.168.216.142:81/dev-api/identify/kuaidi/noToken/getKuaidiInfo
export function getKuaidiInfo(orderId) {
    return request({
        method: 'get',
        url: '/identify/kuaidi/noToken/getKuaidiInfo',
        params: {orderId:orderId},
    })
}
// 发送短信 http://192.168.216.148:81/dev-api/mobile/customer/noToken/sendSms
export function sendSms(params) {
    return request({
        method: 'get',
        url: '/mobile/customer/noToken/sendSms',
        params: params,
    })
}

