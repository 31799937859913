<template>
	<div class="head">
		<div class="head-item">
			<div class="name" @click="goIndex()"></div>
			<div class="col-2">
				<nav class="justify-center">
					<div v-for="(item,index) in link_list" :key="index" class="link_item"
						:class="{'linkItemClick_style': this.$store.state.link_item_index==index}">
					<!-- 	<component :is="item.isMenuLink? 'router-link': 'div'" :to="item.link"
							@click="linkItemClick(index)">
							{{ item.name }}
						</component> -->
						<component :is="item.isMenuLink? 'router-link': 'div'" :to="item.link">
							{{ item.name }}
						</component>
					</div>
				</nav>
			</div>
			<div class="col-3 justify-center align-center">
				<div v-if="userName" @click="logout()">{{userName}},退出</div>
				<template v-else>
					<!-- <div @click="dialogFormVisibleLogonShow(true)">登录</div>
					/
					<div @click="dialogFormVisibleRegisterShow(true)">免费注册</div> -->
					
					<div>登录</div>
					/
					<div>免费注册</div>
				</template>
			</div>
		</div>

		<!-- 登录 -->
		<el-dialog class="dialogFormVisible_logon" v-model="dialogFormVisible_logon" :show-close="true" width="600px">
			<template #header>
				<div class="head_title">{{head_title}}</div>
			</template>
			<div class="content_box">
				<div class="tel_box justify-start">
					<div class="tel_label">手机号</div>
					<div><el-input class="tel_input" v-model="logon_form.tel" placeholder="请输入手机号" /></div>
				</div>
				<div class="tel_box justify-start">
					<div class="tel_label">密码</div>
					<div><el-input class="tel_input" v-model="logon_form.pwd" placeholder="请输入密码" show-password /></div>
				</div>
			</div>
			<div class="forgot_pwd_box">
				<span @click="forgetPwdShow(true)">忘记密码</span>
			</div>
			<template #footer>
				<div class="justify-between red_btn">
					<div class="logon_btn" @click="loginSubmit()">
						<span>登录</span>
					</div>
					<div class="register_btn" @click="dialogFormVisibleRegisterShow(true)">
						<span>注册</span>
					</div>
				</div>
			</template>
		</el-dialog>

		<!-- 注册 -->
		<el-dialog class="dialogFormVisible_register" v-model="dialogFormVisible_register" :show-close="true"
			width="600px">
			<template #header>
				<div class="head_title">注册</div>
			</template>
			<div class="content_box register_content_box">
				<el-form ref="registerRuleForm" :model="register_form" label-width="120px" label-position="left"
					:rules="register_rules">
					<el-form-item label="名称" prop="name">
						<el-input class="_input" v-model="register_form.name"></el-input>
						<div class="tip tip_ul"> 请输入客户名称 </div>
					</el-form-item>
					<el-form-item label="手机号" prop="tel" class="pwd_form_item">
						<el-input class="_input" v-model="register_form.tel"></el-input>
					</el-form-item>
					<el-form-item label="密码" prop="pwd" class="pwd_form_item">
						<el-input class="_input" v-model="register_form.pwd" show-password></el-input>
						<div class="tip tip_ul">
							<div>长度8-32位, 字母/数字/特殊字符至少含2种</div>
						</div>
					</el-form-item>
					<el-form-item label="确认密码" prop="pwd_confirm" class="pwd_form_item">
						<el-input class="_input" v-model="register_form.pwd_confirm" show-password></el-input>
						<!-- <div class="tip tip_ul">
                            <div>· 长度大于8个字符</div>
                            <div>· 字母/数字以及标点符号至少包含2种</div>
                            <div>· 不允许有空格、中文</div>
                        </div> -->
					</el-form-item>
					<el-form-item label="验证码" prop="code" class="pwd_form_item">
						<div class="justify-between">
							<el-input class="_input code" v-model="register_form.code"></el-input>
							<div class="get_code" @click="register_form.code=getCode('registerRuleForm', register_form.tel)">
								{{verfityCode}}
							</div>
						</div>
						<div class="tip">请输入验证码</div>
					</el-form-item>
				</el-form>
			</div>

			<template #footer>
				<div class="justify-center red_btn register_btn_box">
					<div class="logon_btn" @click="registerSubmit('registerRuleForm')">
						<span>快速注册</span>
					</div>
				</div>
			</template>
		</el-dialog>

		<!-- 忘记密码 -->
		<el-dialog class="dialogFormVisible_register dialogFormVisible_forgetPwd" v-model="dialogFormVisible_forgetPwd"
			:show-close="true" width="600px">
			<template #header>
				<div class="head_title">忘记密码</div>
			</template>
			<div class="content_box register_content_box">
				<el-form ref="forgetPwdRuleForm" :model="forgetPwd_form" label-width="120px" label-position="left"
					:rules="forget_rules">
					<el-form-item label="手机号" prop="tel">
						<el-input class="_input" v-model="forgetPwd_form.tel"></el-input>
					</el-form-item>
					<el-form-item label="新密码" prop="pwd" class="pwd_form_item">
						<el-input class="_input" v-model="forgetPwd_form.pwd" show-password></el-input>
						<div class="tip tip_ul">
							<div>长度8-32位, 字母/数字/特殊字符至少含2种</div>
						</div>
					</el-form-item>
					<el-form-item label="确认密码" prop="pwd_confirm" class="pwd_form_item">
						<el-input class="_input" v-model="forgetPwd_form.pwd_confirm" show-password></el-input>
						<div class="tip tip_ul">
							<!-- <div>· 长度为8~14个字符</div>
                            <div>· 字母/数字以及标点符号至少包含2种</div>
                            <div>· 不允许有空格、中文</div> -->
						</div>
					</el-form-item>
					<el-form-item label="验证码" class="pwd_form_item">
						<div class="justify-between">
							<el-input class="_input code" v-model="forgetPwd_form.code"></el-input>
							<div class="get_code" @click="forgetPwd_form.code=getCode('forgetPwdRuleForm', forgetPwd_form.tel)">
								{{verfityCode}}
							</div>
						</div>
						<div class="tip">请输入验证码</div>
					</el-form-item>
				</el-form>
			</div>

			<template #footer>
				<div class="justify-center red_btn register_btn_box">
					<div class="logon_btn" @click="forgetPwdSubmit('forgetPwdRuleForm')">
						<span>确定更改</span>
					</div>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		register,
		login,
		getCustomerInfo,
		sendSms,
		editPwd
	} from '@/api/apiurl'; // 传输方组件调用方式


	export default {
		data() {
			var validateTel = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('手机不能为空'));
				} else if (!/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(value)) {
					callback(new Error('手机格式错误'));
				} else {
					callback();
				}
			};
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('密码不能为空'));
				} else if (!
					/^((?=.*[0-9])(?=.*[a-zA-Z])(?=.*((?=[\x21-\x7e]+)[^A-Za-z0-9]))|(?=.*[0-9])(?=.*[a-zA-Z])|(?=.*[a-zA-Z])(?=.*((?=[\x21-\x7e]+)[^A-Za-z0-9]))|(?=.*[0-9])(?=.*((?=[\x21-\x7e]+)[^A-Za-z0-9]))).{8,32}$/
					.test(value)) {
					callback(new Error('密码安全度不够'));
				} else {
					callback();
				}
			};
			var validateName = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('客户名称不能为空'));
				} else {
					callback();
				}
			};
			var validatePwdConfirm = (rule, value, callback) => {
				if (value !== this.register_form.pwd) {
					callback(new Error('密码不一致'));
				} else {
					callback();
				}
			};
			var validateForgetPwdConfirm = (rule, value, callback) => {
				if (value !== this.forgetPwd_form.pwd) {
					callback(new Error('密码不一致'));
				} else {
					callback();
				}
			};
			return {
				link_list: [{
						name: "首页",
						link: "/",
						isMenuLink: true
					},
					{
						name: "在线送评",
						link: "/comments",
						isMenuLink: false
					},
					{
						name: "公司信息",
						link: "/about",
						isMenuLink: true
					},
					{
						name: "服务项目",
						link: "/news",
						isMenuLink: true
					},
					{
						name: "个人中心",
						link: "/personal",
						isMenuLink: false
					}
				],
				link_item_index: 0,
				userName: this.$cookies.get('userName'),

				dialogFormVisible_logon: false,
				head_title: '登录',
				logon_form: {
					tel: '',
					pwd: ''
				},

				dialogFormVisible_register: false,
				register_form: {
					name: '',
					tel: '',
					pwd: '',
					pwd_confirm: '',
					code: '',
				},
				register_rules: {
					name: [{
						validator: validateName,
						trigger: 'blur'
					}],
					tel: [{
						validator: validateTel,
						trigger: 'blur'
					}],
					pwd: [{
						validator: validatePass,
						trigger: 'blur'
					}],
					pwd_confirm: [{
						validator: validatePwdConfirm,
						trigger: 'blur'
					}],
				},
				forget_rules: {
					tel: [{
						validator: validateTel,
						trigger: 'blur'
					}],
					pwd: [{
						validator: validatePass,
						trigger: 'blur'
					}],
					pwd_confirm: [{
						validator: validateForgetPwdConfirm,
						trigger: 'blur'
					}],
				},

				dialogFormVisible_forgetPwd: false,
				forgetPwd_form: {
					tel: '',
					pwd: '',
					pwd_confirm: '',
					code: '',
				},
				verfityCode: '获取验证码',
				timer: null, //设置计时器
				waittime: '',
			}
		},
		created() {
			if (!this.$cookies.get('userName')) {
				this.link_list[1].isMenuLink = false
				this.link_list[4].isMenuLink = false
			}
		},
		methods: {
			goIndex() {
				this.$router.push({
					path: '/'
				})
			},
			linkItemClick(index) {
				// this.link_item_index = index
				this.$store.commit('setLinkItemIndex', index)
				if (index == 1 || index == 4) {
					//提示登录
					if (!this.$cookies.get('userName')) {
						// this.$message.error('请先登录');
						this.dialogFormVisibleLogonShow(true)

						// this.$alert("请先登录", "提示", {
						// 	confirmButtonText: '确定',
						// 	cancelButtonText: '取消',
						// 	type: 'warning'
						// }).then(() => {
						// 	this.dialogFormVisibleLogonShow(true)
						// }).catch(() => {
						// })
					}
				}
			},
			// 是否展示 - 登录框
			dialogFormVisibleLogonShow(bool) {
				this.dialogFormVisible_logon = bool
			},
			// 是否展示 - 注册框
			dialogFormVisibleRegisterShow(bool) {
				this.dialogFormVisibleLogonShow(false)
				this.dialogFormVisible_register = bool
			},
			// 是否展示 - 修改密码框
			forgetPwdShow(bool) {
				this.dialogFormVisibleLogonShow(false)
				this.dialogFormVisible_forgetPwd = bool
			},
			// 登录提交
			loginSubmit() {
				// this.$message(`手机号：${this.logon_form.tel}，密码：${this.logon_form.pwd}`);
				// this.dialogFormVisibleLogonShow(false)
				if (this.logon_form.tel == '') {
					this.$message.error('请输入登录手机号');
					return false;
				}
				if (!/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.logon_form.tel)) {
					this.$message.error('您输入手机号格式错误');
					return false;
				}
				if (this.logon_form.pwd == '') {
					this.$message.error('请输入密码');
					return false;
				}
				// this.$message(`手机号：${this.logon_form.tel}，密码：${this.logon_form.pwd}`);
				// this.$message('提交数据：'+JSON.stringify(this.logon_form));
				var params = this.logon_form
				var data = {
					"customerPhone": params.tel,
					"passwd": params.pwd
				}
				login(data).then(response => {
					// console.log(response)
					if (response.data.code == 200) {
						this.$message.success('登录成功，请登录');
						var access_token = response.data.data.access_token
						var expires_in = response.data.data.expires_in + 'm'
						var userName = response.data.data.userName
						var userTel = response.data.data.userTel
						// this.$cookies.set('access_token', access_token, expires_in)
						// this.$cookies.set('userName', userName, expires_in)
						// this.$cookies.set('userTel', userTel, expires_in)
						this.$cookies.set('access_token', access_token)
						this.$cookies.set('userName', userName)
						this.$cookies.set('userTel', userTel)
						this.dialogFormVisibleLogonShow(false)
						this.link_list[1].isMenuLink = true
						this.link_list[4].isMenuLink = true
						this.userName = userName
					} else {
						this.$message.error('您输入账户或密码，请仔细检查');
					}
				}).then().catch(err => console.log(err));
			},
			//注册提交
			registerSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// this.$message('提交数据：' + JSON.stringify(this.register_form));
						var params = this.register_form
						var data = {
							"customerName": params.name,
							"customerPhone": params.tel,
							"passwd": params.pwd,
							"confirmPassword": params.pwd_confirm,
							"checkCode": params.code,
						}
						register(data).then(response => {
							if (response.data.code == 200) {
								this.$message.success('注册成功，请登录');
								this.dialogFormVisibleRegisterShow(false)
								this.dialogFormVisibleLogonShow(true)
							} else {
								// alert('数据传递，请检查----------');
								this.$message.error(response.data.msg);
							}
						}).then().catch(err => console.log(err));
					} else {
						// this.$message.error('注册资料填写有误，请仔细检查');
						return false;
					}
				});
			},
			//修改密码提交
			forgetPwdSubmit(formName) {
				// this.$message(JSON.stringify(this.forgetPwd_form));
				// this.forgetPwdShow(false)
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// this.$message('提交数据：' + JSON.stringify(this.forgetPwd_form));
						var params = this.forgetPwd_form
						var data = {
							"customerPhone": params.tel,
							"smsCode": params.code,
							"passwd": params.pwd,
						}
						editPwd(data).then(response => {
							if (response.data.code == 200) {
								this.$message.success('新密码修改成功，请登录');
								this.logout()
								this.dialogFormVisibleRegisterShow(false)
								this.dialogFormVisibleLogonShow(true)
							} else {
								// alert('数据传递，请检查----------');
								this.$message.error('新密码修改失败，请仔细检查');
							}
						}).then().catch(err => console.log(err));
					} else {
						this.$message.error('新密码资料填写有误，请仔细检查');
						return false;
					}
				});
			},
			// 获取短信验证码
			getCode(formName, tel) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let TIME_COUNT = 60;
						if (!this.timer) {
							this.waittime = TIME_COUNT
							sendSms({'telPhone': tel}).then(response => {
								this.timer = setInterval(() => {
									if (this.waittime > 0 && this.waittime <= TIME_COUNT) {
										this.verfityCode = this.waittime + 's后重新发送'
										this.waittime--;
									} else {
										this.verfityCode = "获取验证码";
										clearInterval(this.timer);
										this.timer = null;
									}
								}, 1000);
							}).then().catch(err => console.log(err));
						}
					}
				})

			},
			//退出登录
			logout() {
				this.$message.success('您已退出登录状态');
				this.$cookies.remove('access_token');
				this.$cookies.remove('userName')
				this.userName = ''
				//跳转到首页
				this.$router.push({path: '/'})
			}
		},
	}
</script>

<style lang="less" scoped>
	@import "~@/static/css/Head.less";
</style>