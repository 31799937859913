<template>
  <div class="foot">
    <div class="row-1 justify-between">
      <div class="col-3">
        <div class="email">
          联系我们：{{companyInfo.tel}}
        </div>
        <div>地址：{{companyInfo.address}}</div>
        <div>
			送评收货地址: {{companyInfo.rateAddress}}
		</div>
		<div>电子邮箱: {{companyInfo.email}}</div>
      </div>
	  
      <div class="col-1">
        <div>
          <div class="link" @click="goPage('/about')">
            关于我们
          </div>
          <div class="link">
            <!-- 联系我们 -->&nbsp;
          </div>
          <div class="link" @click="goPage('/news')">
            帮助中心
          </div>
        </div>
      </div>
	      
      <div class="col-1">
        <div>请您关注我们或与我们取得联系</div>
        <div class="justify-between">
          <div class="foot-code">
			<img src="~@/static/images/gwjc-gzh.jpg" alt="">
			<div>公众号</div>
          </div>
          <div class="foot-code">
			<img src="~@/static/images/mini-suncode.jpg" alt="">
			<div>小程序</div>
          </div>
          <div class="foot-code">
			<img :src="companyInfo.wxImage" alt="" v-if="companyInfo.wxImage">
			<img src="~@/static/images/QRcode.png" alt="" v-else>
			<div>微信客服</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row-2">
      版权所有copyright 国文鉴定 <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023014456号-1</a>
	  <img src="~@/static/images/icon-gongan.png" class="icon-gongan"/>
	  <a href= "https://beian.mps.gov.cn/#/query/webSearch?code=11011302006790" rel="noreferrer" target="_blank">京公网安备11011302006790</a>
    </div>
  </div>
</template>

<script>
import {getGoodsNum, listNoToken} from '@/api/apiurl';
export default {
	data() {
		return {
			companyInfo: {},
		}
	},
	created() {
		getGoodsNum().then(response => {
			// console.log(response.data)
			if(response.data.code == 200){
				this.companyInfo = response.data.data.companyInfo
			}
		})
	},
	methods: {
		goPage(link) {
			console.log(link)
			if(link == '/about') this.$store.commit('setLinkItemIndex', 2)
			if(link == '/news') this.$store.commit('setLinkItemIndex', 3)
			this.$router.push({ path: link})
		}
	},
}
</script>

<style lang="less" scoped>@import "~@/static/css/Foot.less";</style>