<template>
  <div class="home flex-col">
	<div class="top">
		<!-- <div class="num">{{goodsNum}}</div>
		<div class="chinese">至此，我们已累计鉴定超过{{goodsNumWan}}万次</div>
		<div class="english">So far, we have identified more than {{goodsNumMillion}} million times in total</div>
		<div class="zxsp" @click="comments()">在线送评</div> -->
	</div>

    <div class="search justify-center">
        <div class="img_box">
			<img :src="adImage.upImage" alt="" v-if="adImage.upImage" />
			<img src="~@/static/images/picture_1.png" alt="" v-else/>
        </div>
        <div class="search_input_box">
            <div class="search_input">
                <el-input maxlength="32" v-model="commodity_code" class="w-50 m-2" placeholder="请输入商品编码">
                    <template #suffix>
                        <div class="icon_search_div" @click="search()"><img class="icon_search" src="~@/static/images/icon_search.png" alt="" /></div>
                    </template>
                </el-input>
            </div>
			<div class="search_two">
				<div class="search_tip">
					只需输入评级藏品的编码，即可查询藏品的鉴定信息(日期、造币厂厂标、面额、版别和等级将会显示)
				</div>
				<div class="topa">
					<!-- <div class="zxsp" @click="comments()">在线送评</div> -->
					<div class="zxsp">在线送评</div>
				</div>
			</div>
            
        </div>
    </div>
  
	<div class="bottom">
		<div class="news">
			<div class="news_item" v-for="(item,index) in news" :key="index">
				<div class="title_box" @click="lookDetails(item.noticeId)">
					<div class="dot">·</div> 
					<div class="title">{{item.noticeTitle}}</div>
				</div>
				<div class="date_box" v-if="index < 5">
					<span class="date">{{ formatDate(item.createTime).split('-')[1]}}</span>
					/<span class="month">{{ formatDate(item.createTime).split('-')[0]}}</span>
				</div>
			</div>
			<div class="more" @click="more()">更多>></div>
		</div>
		<div class="picture_box" v-if="news">
			<img :src="adImage.downImage" alt="" v-if="adImage.downImage" />
			<img src="~@/static/images/picture_2.png" alt="" v-else/>
		</div>
	</div>
  </div>
</template>

<script>
import {getGoodsNum, listNoToken, getPageImage} from '@/api/apiurl';

export default {
	name: 'HomeView',
	data() {
		return {
			goodsNum : '2,100,100,000',
			goodsNumWan : '200',
			goodsNumMillion : '2',
			commodity_code: '',
			news: [],
			adImage: {upImage:'',downImage:''},
		}
	},
	created() {
		getGoodsNum().then(response => {
			if(response.data.code == 200){
				const goodsNumTemp = response.data.data.goosNum.toString()
				this.goodsNum = goodsNumTemp.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
				this.goodsNumWan =parseInt(goodsNumTemp/10000)
				this.goodsNumMillion =parseInt(goodsNumTemp/1000000)
			}
		})
		listNoToken().then(response => {
			// console.log(response.data)
			this.news = response.data.data.slice(0, 5)
			
			// const formattedDate = formatDate(dateStr);
		})
		getPageImage().then(response => {
			if(response.data.code == 200){
				this.adImage = response.data.data
			}
		})
	},
	
	methods: {
		more() {
			this.$router.push({ path: '/More'})
		},
		lookDetails(noticeId) {
			this.$router.push({ path: '/Detail', query: {noticeId: noticeId}})
		},
		search() {
			if(this.commodity_code == ''){
                this.$message.warning('请填写商品编码');
				return false;
			}
			this.$router.push({ path: '/searchDetails', query: {code: this.commodity_code}})
		},
		//在线送评
		comments() {
			//提示登录
			if(!this.$cookies.get('userName')){
				this.$message.error('请先登录');
				return false;
			}
			this.$store.commit('setLinkItemIndex', 1)
			this.$router.push({ path: '/comments'})
		},
		
		formatDate(dateStr) {
		  const date = new Date(dateStr);
		  const year = date.getFullYear();
		  const month = String(date.getMonth() + 1).padStart(2, '0');
		  const day = String(date.getDate()).padStart(2, '0');
		  return `${month}-${day}`;
		}
		
	},
}
</script>
<style lang="less" scoped>
.body {
    //background-image: url("~@/static/images/bc_index.png");
	background-image: url("~@/static/images/1111.jpg");
	background-size: 100%;
}
.home{
	width: 100%;
}
.search_two{
	display: flex;
	
	
}
.topa{
	// min-width: 1024px;
    box-sizing: border-box;
    //padding-top: 149px;
    // padding-left: 300px;
	//margin-left: 15%;
	//margin-right: 15%;	
	width: 20%;
	display: flex;
	justify-content: flex-end;
	
	.zxsp {
        width: 300px;
        height: 61px;
        background: #AC0D00;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        font-size: 25px;
        font-family: PuHuiTiMedium;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 91px;
        text-align: center;
        margin-top: 25px;
        cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
    }
}
.top {
	// min-width: 1024px;
    box-sizing: border-box;
    padding-top: 149px;
    // padding-left: 300px;
	margin-left: 15%;
	margin-right: 15%;	
	height: 500px;
    .num {
        font-size: 110px;
        font-family: AlibabaBold;
        font-weight: bold;
        color: #232323;
        line-height: 132px;
    }
    .chinese {
        font-size: 60px;
        font-family: PuHuiTiMedium;
        font-weight: 500;
        color: #949494;
    }
    .english {
        font-size: 26px;
        font-family: PuHuiTiMedium;
        font-weight: 500;
        color: #949494;
    }
    .zxsp {
        width: 362px;
        height: 91px;
        background: #AC0D00;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        font-size: 30px;
        font-family: PuHuiTiMedium;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 91px;
        text-align: center;
        margin-top: 18px;
        cursor: pointer;
    }
}

.search {
    // width: 100%;
    height: 293px;
    background: #000000;
    border-radius: 6px 6px 6px 6px;
    opacity: 0.8;
    margin-top: 34px;
    padding-top: 45px;
	margin-left: 5%;
	margin-right: 5%;
	// .search-box{
		
	// }
    .img_box {
        height: 219px;
        img {
            height: 100%;
        }
    }
    .search_input_box {
        margin-left: 5%;
        width: 60%;
        /deep/ .el-input__wrapper {
            width: 100%;
            // width: 1055px;
            height: 70px;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.15);
            border-radius: 2px 2px 2px 2px;
            opacity: 1;
            font-size: 30px
        }
        .icon_search_div {
            padding-right: 52px;
            padding-top: 10px;
            cursor: pointer;
        }
        .icon_search {
            width: 28px;
        }
        .search_tip {
            margin-top: 25px;
            font-size: 21px;
            font-family: PingFangRegular;
            font-weight: 400;
            color: #FFFFFF;
			width: 80%;
			padding-right: 15px;
        }
    }
}

.bottom {
    // padding-left: 300px;
    margin-top: 5%;
    // padding-bottom: 99px;
    padding-bottom: 5%;
    padding-left: 15%;
    padding-right: 15%;

	display: flex;
	justify-content: space-between;
    .news {
		width: 60%;
        .news_item {
            font-size: 24px;
            font-family: PingFangRegular;
            font-weight: 400;
            color: #090909;
            margin-bottom: 35px;
			line-height: 34px;
			height: 34px;
			
			.title_box{
				display: flex;
				float: left;
				cursor: pointer;
				// min-width: 520px;
				width: 80%;
				.dot{
					padding-right: 18px;
				}
				.title{
					line-height: 34px;
					height: 34px;
					overflow: hidden;
				}
			}
			.date_box {
				float: right;
				color: #B7B7B7;
				margin-left: 5%;
				width: 15%;
				.date {
					font-size: 26px;
					font-family: PingFangRegular;
					font-weight: 400;
					color: #B7B7B7;
				}
				.month {
					font-size: 17px;
					font-family: PingFangRegular;
					font-weight: 400;
					color: #B7B7B7;
				}
			}
        }
		.more {
			// padding-left: 3%;
			padding-left: 30px;
			font-size: 24px;
			font-family: PingFangRegular;
			font-weight: 400;
			color: #040404;
			cursor: pointer;
		}
    }
    .picture_box {
		width: 40%;
        // width: 630px;
        height: 405px;
        margin-left: 44px;
        img {
            width: 100%;
        }
    }
}
</style>
