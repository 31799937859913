<template>
    <v-head/><!-- 头 -->
    <router-view class="body" />
    <v-foot/><!-- 尾 -->
</template>

<script>
    import Foot from './components/Foot.vue'
    import Head from './components/Head.vue'
    export default {
        components: {
            "v-foot": Foot,
            "v-head": Head
        }
    }
</script>

<style>@import "~@/static/css/common.css";</style>
<style>@import "~@/static/css/app.css";</style>
<style lang="less">@import "~@/static/css/app.less";</style>
<style lang="less">@import "~@/static/css/elementUi.less";</style>