<template>
    <div class="page_box">
        <div class="page">
            <div class="breadcrumb justify-start">
                <div>
                    <span>您的位置：</span>
                    <span><a href="/">首页</a></span>
                    <span> > </span>
                    <span>服务项目</span>
                </div>
            </div>

            <div class="news">
                <div class="item justify-between" @click="lookDetails(item.noticeId)" v-for="(item,index) in news" :key="index">
                    <div class="left">
                        <div class="row-1">{{item.noticeTitle}}</div>
                        <div class="row-2">{{item.noticeAbstract}}</div>
                        <div class="row-3">{{item.createTime}}</div>
                    </div>
                    <div class="right">
						<img class="right-image" :src="item.noticeImg" alt="" v-if="item.noticeImg" />
						<img class="right-image" src="~@/static/images/picture_2.png" alt="" v-else/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {listNoToken5} from '@/api/apiurl';
export default {
	data() {
		return {
			commodity_code: '',
			news: []
		}
	},
	created() {
		listNoToken5().then(response => {
			console.log(response.data)
			this.news = response.data.data
		})
	},
	methods: {
		goIndex() {
			this.$router.push({ path: '/'})
		},
		lookDetails(noticeId) {
			console.log({noticeId: noticeId})
			this.$router.push({ path: '/newsDetails', query: {noticeId: noticeId}})
		}
	},
}
</script>

<style lang="less" scoped>
.page_box {
    border: 1px transparent solid;
}
.news {
    margin-left: 276px;
    margin-right: 276px;
    margin-top: 51px;
    margin-bottom: 229px;
    .item {
        cursor: pointer;
        border-bottom: 1px solid #B7B7B7;
        padding-bottom: 38px;
        margin-bottom: 50px;
		.left{
			.row-1 {
				font-size: 26px;
				font-family: PingFangHeavy;
				font-weight: 800;
				color: #090909;
			}
			.row-2 {
				margin-top: 20px;
				font-size: 22px;
				font-family: PingFangRegular;
				font-weight: 400;
				color: #090909;
				width: 70%;
			}
			.row-3 {
				margin-top: 64px;
				font-size: 22px;
				font-family: PingFangRegular;
				font-weight: 400;
				color: #B7B7B7;
			}
		}
		.right{
			width: 270px;
			height: 180px;
			overflow: hidden;
			text-align: center;
			.right-image{
				width: auto;
				height: auto; 
				max-width: 100%; 
				max-height: 100%;
			}
		}
    }
}
</style>