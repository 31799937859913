<template>
    <div class="page_box">
        <div class="page">
            <div class="breadcrumb justify-start">
                <div>
                    <span>您的位置：</span>
                    <span><a href="/">首页</a></span>
                    <span> > </span>
                    <span><a href="#/news">更多文章</a></span>
                    <span> > </span>
                    <span>文章内容</span>
                </div>
            </div>

            <div class="news justify-center">
                <div class="item">
                    <div class="row-1">{{title}}</div>
                    <div class="row-2">{{createTime}}</div>
                    <!-- <div class="row-3 justify-center">
						<img :src="noticeImg" alt="" v-if="noticeImg" />
						<img src="~@/static/images/picture_3.png" alt="" v-else/>
					</div> -->
                    <div class="row-4">
                        <!-- <div>2017年5月18-20日，欢迎光临中国国际钱币(北京)展销会CCGS展位。</div>
                        <div>CCGS将于2017年5月18-20日参加每年一度的盛事——中国国际钱币(北京)展销会。届时欢迎光临北京京都信苑饭店，我们的展位在B15</div>
                        <div>了解CCGS详情，请浏览www.zhongqianguoxin.com，亦可致电010-56089855或电邮至 / E-mail: lilf@chinacoin.com</div>
                        <div>了解中国国际钱币(北京)展销会的详情，请浏览www.chinacoinshow.com</div> -->
						<div class="ql-editor" v-html="content"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getInfoNoToken} from '@/api/apiurl';
import "@/static/css/quill.snow.css";
export default {
	name: 'HomeView',
	data() {
		// return {
		// 	dialogFormVisible: false,
		// 	startTime: '',
		// 	startX: '',
		// 	startY: '',
		// 	moveY: '',
		// 	moveX: '',
		// 	imageList: [], //图片
		// 	otherSpecList: [], //自定义属性
		// 	loading: false,
		// 	goods: {}
		// }
		return {
			title: "CCGS参展2017年中国国际钱币(北京)展销会",
			createTime: '2023-02-02 02:02:02',
			content: '2017年5月18-20日，欢迎光临中国国际钱币(北京)展销会CCGS展位。',
			noticeImg: '~@/static/images/picture_3.png'
		}
	},
	created() {
		let query = this.$route.query
		if(query.noticeId < 1){
			this.$router.push({ path: '/'})
			return false;
		}
		console.log('当前id', query)
		getInfoNoToken(query.noticeId).then(response => {
			console.log(response.data.data)
			this.title 	= response.data.data.noticeTitle
			this.createTime 	= response.data.data.createTime
			this.content 		= response.data.data.noticeContent
			this.noticeImg 		= response.data.data.noticeImg
		})
		// let query = this.$route.query
		// console.log(this.$route.query)
		// if(!query.code){
		// 	this.$router.push({ path: '/'})
		// 	return false;
		// }
		// getGoodsInfoByCode(query.code).then(response => {
		// 	console.log(response.data.data)
			
		// 	if(response.data.code === 200){
		// 		this.goods = response.data.data
		// 		if (response.data.otherSpec) {
		// 		  this.otherSpecList = JSON.parse(response.data.data.otherSpec)
		// 		}
		// 		if (response.data.data.images) {
		// 			this.imageList = response.data.data.images.split(',')
		// 			console.log(this.imageList)
		// 		}
		// 	}
		// }).catch(() => {
		// 	this.loading = false
		// })
	},
	methods: {
		goIndex() {
			this.$router.push({ path: '/'})
		},
		// query() {
		// 	this.$message('提交数据：'+JSON.stringify(this.form));
		// 	this.dialogFormVisible = false
		// },
		// start(e) {
		// 	this.startX = e.touches[0].clientX
		// 	this.startY = e.touches[0].clientY
		// },
		// move(e) {
		// 	this.moveX = e.touches[0].clientX
		// 	this.moveY = e.touches[0].clientY
		// 	var nowtime = new Date().getTime()
		// 	if (this.startTime === undefined || nowtime > this.startTime) {
		// 		if (this.startX - this.moveX <= 0) { // 右滑触发
		// 			this.prev()
		// 			return false
		// 		} else {
		// 			this.next()
		// 			return false
		// 		}
		// 	}
		// },
		// // viewAnnex(item) {
		// //   window.open(item);
		// // },
		// prev() {
		// 	this.$refs.nop.prev()
		// 	this.startTime = new Date().getTime() + 500
		// },
		// next() {
		// 	this.$refs.nop.next()
		// 	this.startTime = new Date().getTime() + 500
		// },
	},
}
</script>
<style lang="less" scoped>
.page_box {
    border: 1px transparent solid;
}
.page{
}
.news {
    margin-left: 276px;
    margin-right: 276px;
    margin-top: 51px;
    margin-bottom: 229px;
    .item {
        // border-bottom: 1px solid #B7B7B7;
        padding-bottom: 38px;
        margin-bottom: 50px;
        .row-1 {
            font-size: 26px;
            font-family: PingFangHeavy;
            font-weight: 800;
            color: #090909;
            text-align: center;
        }
        .row-2 {
            margin-top: 20px;
            font-size: 22px;
            font-family: PingFangRegular;
            font-weight: 400;
            color: #B7B7B7;
            text-align: center;
        }
        .row-3 {
            text-align: center;
            margin-top: 30px;
            img {
                width: 494px;
                height: 364px;
            }
        }
        .row-4 {
			width: 1280px;
            margin-top: 45px;
            font-size: 22px;
            font-family: PingFangRegular;
            font-weight: 400;
            color: #090909;
            div {
                margin-bottom: 20px;
            }
        }
    }
}
</style>