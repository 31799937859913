import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NewsView from '../views/NewsView.vue'
import NewsDetailsView from '../views/NewsDetailsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
	meta:{title:"首页-国文鉴定"}
  },
  {
    path: '/news',
    name: 'news',
    component: NewsView,
	meta:{title:"服务项目-国文鉴定"}
  },
  {
    path: '/newsDetails',
    name: 'newsDetails',
    component: NewsDetailsView,
	meta:{title:"文章内容-国文鉴定"}
  },
  {
    path: '/more',
    name: 'more',
    component: () => import(/* webpackChunkName: "about" */ '../views/MoreView.vue'),
	meta:{title:"公告列表-国文鉴定"}
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/DetailView.vue'),
	meta:{title:"文章内容-国文鉴定"}
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
	meta:{title:"关于我们-国文鉴定"}
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue'),
	meta:{title:"联系我们-国文鉴定"}
  },
  {
    path: '/searchDetails',
    name: 'searchDetails',
    component: () => import(/* webpackChunkName: "searchDetails" */ '../views/SearchDetailsView.vue'),
	meta:{title:"藏品详情-国文鉴定"}
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import(/* webpackChunkName: "searchDetails" */ '../views/PersonalView.vue'),
	meta:{title:"个人中心-国文鉴定"}
  },
  {
    path: '/setUp',
    name: 'setUp',
    component: () => import(/* webpackChunkName: "setUpView" */ '../views/SetUpView.vue'),
	meta:{title:"设置-国文鉴定"}
  },
  {
    path: '/comments',
    name: 'comments',
    component: () => import(/* webpackChunkName: "comments" */ '../views/CommentsView.vue'),
	meta:{title:"在线送评-国文鉴定"}
  },
  {
    path: '/address',
    name: 'address',
    component: () => import(/* webpackChunkName: "address" */ '../views/AddressView.vue'),
	meta:{title:"更换地址-国文鉴定"}
  },
  {
	path: '/:catchAll(.*)',
	hidden: true,
	component: () => import('@/views/error/404.vue')//这个是我自己的路径
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to,from,next)=>{//beforeEach是router的钩子函数，在进入路由前执行
    if(to.meta.title){//判断是否有标题
        document.title = to.meta.title
    }
    next()  //执行进入路由，如果不写就不会进入目标页
})

export default router
